<template>
  <TTView>
    <VRow>
      <VCol>
        <PositionsTableContainer />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import PositionsTableContainer from '../../components/competency-matrix/positions/PositionsTableContainer.vue';

export default {
  name: 'Positions',

  components: {
    PositionsTableContainer,
  },
};
</script>
