/* eslint-disable import/prefer-default-export */

const DEFAULT_POSITION = {
  id: null,
  name: null,
  companyId: null,
};

const DEFAULT_POSITIONS_USER_MATRIX = {
  userId: null,
  companyId: null,
};

export { DEFAULT_POSITION, DEFAULT_POSITIONS_USER_MATRIX };
