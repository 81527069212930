<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="positions"
    :loading="loading"
    v-bind="{...$attrs, ...$props}"
    v-on="$listeners"
  >
    <template #top>
      <VToolbar flat>
        <VToolbarTitle>Позиции</VToolbarTitle>

        <VSpacer />

        <slot name="topActions" />
      </VToolbar>
    </template>

    <template #item.companyId="{ item }">
      {{ companyName(item) }}
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'PositionsTable',

  props: {
    companies: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    positions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Название',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Компания',
          align: 'left',
          sortable: true,
          value: 'companyId',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },

  methods: {
    companyName(item) {
      const company = this.companies.find((c) => c.id === item.companyId);

      if (company) return company.name;

      return '';
    },
  },
};
</script>
