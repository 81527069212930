var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PositionsTable',{attrs:{"companies":_vm.companies,"loading":_vm.loading,"positions":_vm.positions,"disable-pagination":false,"hide-default-footer":false,"items-per-page":_vm.pagination.limit,"page":_vm.pagination.page,"server-items-length":_vm.pagination.total,"footer-props":{
    itemsPerPageOptions : _vm.pagination.itemsPerPageOptions,
    showFirstLastPage : true
  }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"topActions",fn:function(){return [_c('VBtn',{attrs:{"color":"primary","to":{
        name : _vm.Names.R_COMPETENCY_MATRIX_POSITION_CREATE
      }}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-plus ")]),_vm._v(" Новая позиция ")],1),_c('VDialog',{attrs:{"max-width":"500px"},on:{"click:outside":_vm.handleCancel},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('VCard',[_c('VCardTitle',{staticClass:"headline"},[_vm._v(" Удалить позицию ")]),_c('VCardText',[_vm._v(" Позиция "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.position.name))]),_vm._v(" будет удалена! "),_c('br'),_vm._v(" Действительно хотите удалить позицию? ")]),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Нет ")]),_c('VBtn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.handleDelete}},[_vm._v(" Да ")])],1)],1)],1)]},proxy:true},{key:"rowActions",fn:function(ref){
      var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"blue","to":{
            name : _vm.Names.R_COMPETENCY_MATRIX_POSITION,
            params : {
              positionId : item.id
            }
          }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"orange","to":{
            name : _vm.Names.R_COMPETENCY_MATRIX_POSITION_EDIT,
            params : {
              positionId : item.id
            }
          }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактирование")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deletePosition(item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удаление")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","to":{
            name : _vm.Names.R_COMPETENCY_MATRIX_POSITION_COMPETENCIES,
            params : {
              positionId : item.id
            }
          }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-user-tie ")])],1)]}}],null,true)},[_c('span',[_vm._v("Компетенции")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","to":{
            name : _vm.Names.R_COMPETENCY_MATRIX_POSITION_USERS,
            params : {
              positionId : item.id
            }
          }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-user ")])],1)]}}],null,true)},[_c('span',[_vm._v("Пользователи")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }