<template>
  <PositionsTable
    :companies="companies"
    :loading="loading"
    :positions="positions"
    :disable-pagination="false"
    :hide-default-footer="false"
    :items-per-page.sync="pagination.limit"
    :page.sync="pagination.page"
    :server-items-length="pagination.total"
    :footer-props="{
      itemsPerPageOptions : pagination.itemsPerPageOptions,
      showFirstLastPage : true
    }"
  >
    <template #topActions>
      <VBtn
        color="primary"
        :to="{
          name : Names.R_COMPETENCY_MATRIX_POSITION_CREATE
        }"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Новая позиция
      </VBtn>

      <VDialog
        v-model="confirmDialog"
        max-width="500px"
        @click:outside="handleCancel"
      >
        <VCard>
          <VCardTitle class="headline">
            Удалить позицию
          </VCardTitle>

          <VCardText>
            Позиция <span class="red--text">{{ position.name }}</span> будет удалена! <br>
            Действительно хотите удалить позицию?
          </VCardText>

          <VCardActions>
            <VSpacer />

            <VBtn
              text
              @click="handleCancel"
            >
              Нет
            </VBtn>

            <VBtn
              color="red darken-1"
              text
              @click="handleDelete"
            >
              Да
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>

    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{
              name : Names.R_COMPETENCY_MATRIX_POSITION,
              params : {
                positionId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="orange"
            class="mx-1"
            :to="{
              name : Names.R_COMPETENCY_MATRIX_POSITION_EDIT,
              params : {
                positionId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click.stop="deletePosition(item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            class="mx-1"
            :to="{
              name : Names.R_COMPETENCY_MATRIX_POSITION_COMPETENCIES,
              params : {
                positionId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-user-tie
            </VIcon>
          </VBtn>
        </template>

        <span>Компетенции</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            class="mx-1"
            :to="{
              name : Names.R_COMPETENCY_MATRIX_POSITION_USERS,
              params : {
                positionId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-user
            </VIcon>
          </VBtn>
        </template>

        <span>Пользователи</span>
      </VTooltip>
    </template>
  </PositionsTable>
</template>

<script>
import { DEFAULT_POSITION } from './common';
import PositionsTable from './PositionsTable.vue';

export default {
  name: 'PositionsTableContainer',

  components: {
    PositionsTable,
  },

  inject: ['Names'],

  data() {
    return {
      companies: [],
      confirmDialog: false,
      loading: false,
      position: { ...DEFAULT_POSITION },
      positions: [],
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        itemsPerPageOptions: [10, 50, 100, 500, 1000, -1],
      },
    };
  },

  watch: {
    'pagination.page': {
      handler(page) {
        this.fetch({ page });
      },
    },
    'pagination.limit': {
      handler(limit) {
        // TODO: убрать этот хак после выхода таски с поиском
        // сервер не понимает -1, передаем "максимальное" значение
        this.fetch({ limit: limit !== -1 ? limit : 100000 });
      },
    },
  },

  async created() {
    await this.fetch({ page: 1 });
  },

  methods: {
    async fetch({ page = this.pagination.page, limit = this.pagination.limit }) {
      try {
        this.loading = true;
        const [companiesResponse, positionsResponse] = await Promise.all([
          this.$di.api.Account.indexCompany(),
          this.$di.api.CompetencyMatrix.V4PositionsIndex({ page, limit }),
        ]);

        this.companies = companiesResponse.companies || [];
        this.positions = positionsResponse.competencyGroups || [];
        this.pagination.total = positionsResponse.total || 0;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    resetPositionEdit() {
      this.confirmDialog = false;
      this.position = { ...DEFAULT_POSITION };
    },

    handleCancel() {
      this.resetPositionEdit();
    },

    async handleDelete() {
      try {
        this.loading = true;
        const { id } = this.position;
        const data = { id };

        await this.$di.api.CompetencyMatrix.PositionsDelete(data);

        this.resetPositionEdit();
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        // no-finally
        this.loading = false;
        await this.fetch();
      }
    },

    deletePosition(item) {
      this.confirmDialog = true;
      this.position = { ...item };
    },
  },
};
</script>
